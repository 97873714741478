import { useStorage } from '@vueuse/core'

import type { ProductSearchResultFragment } from '#graphql-operations'

export function useProductHistory() {
  const history = useStorage<ProductSearchResultFragment[]>('product-history', [])

  const addItemToHistory = (item: ProductSearchResultFragment) => {
    const index = history.value.findIndex(i => i.id === item.id)
    if (!~index) {
      history.value.unshift(item)
      history.value = history.value.slice(0, 10)
    } else {
      history.value.splice(index, 1)
      history.value.unshift(item)
    }
  }

  const clearProductHistory = () => {
    history.value = []
  }

  return {
    history,
    addItemToHistory,
    clearProductHistory,
  }
}
