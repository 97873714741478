<script setup lang="ts">
const props = defineProps<{ rating: number }>()

const width = computed(() => ((props.rating / getRating.MAX_RATING) * 100) + 0.5 >> 0)
</script>

<template>
  <div class="inline-flex">
    <span class="relative inline-flex">
      <span class="inline-flex">
        <NIcon
          v-for="n in getRating.MAX_RATING" :key="n"
          class="text-[0.6rem] text-context sm:text-xs"
          icon="ph:star" n="slate3"
        />
      </span>

      <span class="absolute inline-flex overflow-hidden whitespace-nowrap" :style="{ width: `${width}%` }">
        <NIcon
          v-for="n in getRating.MAX_RATING" :key="n"
          class="text-[0.6rem] text-context sm:text-xs" icon="ph:star-fill" n="amber4"
        />
      </span>
    </span>
  </div>
</template>
